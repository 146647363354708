/* You can add global styles to this file, and also import other style files */

a.proximamente-button,
a.more-detail-button {
    background: #d3e7ff none repeat scroll 0 0;
    border-radius: 50px;
    color: #3680ee;
    padding: 10px 15px;
    text-transform: capitalize;
}

a.button-right {
    float: right;
    margin-top: -10px;
}

.title-link {
    cursor: pointer;
    min-height: 48px;
}

.swal-modal {
    width: inherit;
}

.single-course > img {
    width: 100%;
    min-height: 405px;
}

.course-details {
    min-height: 318px;
}

.course-description {
    min-height: 90px;
}
